<template>
  <div class="detail-style">
    <div class="bigbox">
      <div class="box3-fa-fa">
        <template v-if="device == 'h5'">
          <div class="bb-t1" v-if="info.collection_id != 0" @click="goAhead(`/collectionshop?id=${info.collection_id}`)">{{info.collectionname}}</div>
          <div class="box1 between-center">
            <div>{{info1.title}}#{{info1.tokenId}}</div>
            <div class="end-center" v-if="type != 1" @click="goLike">
              <!-- 1 已喜欢 2未喜欢 -->
              <img v-if="info.is_like == 1" src="@/assets/img/img18.png" alt="" class="box1-i1" />
              <img v-else src="@/assets/img/img19.png" alt="" class="box1-i1" />
              <div class="box1-t1">{{info1.heat}}</div>
            </div>
          </div>
          <img :src="$http.imgurl+info1.logoimage" alt="" class="box2" />
          <template v-if="type != 1">
            <div class="box6 between-center">
              <img :src="$http.imgurl+info.avatar" alt="" class="box6-i1" />
              <div class="b6-b1">{{info.nickname}}</div>
              <div class="b6-b2 end-center">
                <!-- is_fans 0未关注 1已关注 2自己的 -->
                <img v-if="info.is_fans == 0" src="@/assets/img/img56.png" alt="" class="box6-i2" @click="gofans" />
                <img v-else-if="info.is_fans == 1" src="@/assets/img/img64.png" alt="" class="box6-i2" @click="gofans" />
                <img v-else src="@/assets/img/img56.png" alt="" class="box6-i2" />
                <img src="@/assets/img/img57.png" alt="" class="box6-i3" />
              </div>
            </div>
            <div class="box7">
              <el-card class="b7-b1">{{$t('text.t115')}}：{{info.price}} USDT</el-card>
            </div>
          </template>
        </template>
        <div class="pc-box3-left center-center" v-if="device == 'pc'">
          <img :src="$http.imgurl+info1.logoimage" alt="" class="box2" />
        </div>
        <div class="box3-fa">
          <div class="pc-add-box" v-if="device == 'pc'">
            <div class="pab-t1" v-if="info.collection_id != 0" @click="goAhead(`/collectionshop?id=${info.collection_id}`)">{{info.collectionname}}</div>
            <div class="pab-t2">{{info1.title}}#{{info1.tokenId}}</div>
            <div class="pab-t3 start-center">
              <img v-if="info.is_fans == 0" src="@/assets/img/img56.png" alt="" class="pab-i1" @click="gofans" />
              <img v-else-if="info.is_fans == 1" src="@/assets/img/img64.png" alt="" class="pab-i1" @click="gofans" />
              <img v-else src="@/assets/img/img56.png" alt="" class="pab-i1" />
              <div v-if="type==1">--</div>
              <div v-else>{{info.nickname}}</div>
            </div>
            <div class="pab-t3 start-center">
              <template v-if="info.orderno">
                <img v-if="info.is_like == 1" @click="goLike" src="@/assets/img/img18.png" alt="" class="pab-i2" />
                <img v-else @click="goLike" src="@/assets/img/img19.png" alt="" class="pab-i2" />
              </template>
              <img v-else src="@/assets/img/img19.png" alt="" class="pab-i2" />
              <div v-if="type==1">--</div>
              <div v-else>{{info1.heat}}</div>
            </div>
            <div class="pab-t3">
              <div class="pab-t3-mb">{{$t('text.t115')}}:</div>
              <div v-if="type==1">--</div>
              <div v-else>{{info.price}} USDT</div>
            </div>
          </div>
          <div class="box3 center-center" v-if="type == 1" @click="goAhead(`/upinfo?collection_id=${info.collection_id}&collectionname=${info.collectionname}&title=${info1.title}&tokenId=${info1.tokenId}&nft_id=${info.id}&img=${$http.imgurl+info1.logoimage}&type=${info.type}`)">
            <img src="@/assets/img/img54.png" alt="" class="box3-i1" />
            <div>{{$t('text.t36')}}</div>
          </div>
          <div class="box3 center-center" v-else-if="type == 2" @click="makePshow">
            <img src="@/assets/img/img54.png" alt="" class="box3-i1" />
            <div>{{$t('text.t100')}}</div>
          </div>
          <div class="box3 center-center" v-else-if="type == 3" @click="makePshow">
            <img src="@/assets/img/img37.png" alt="" class="box3-i1" />
            <div>{{$t('text.t101')}}</div>
          </div>
        </div>
      </div>
      <div :class="device == 'pc'?'box8-fa':''">
        <div class="box8">
          <div class="b8-t1">{{$t('text.t102')}}</div>
          <div class="b8-t2 between-center">
            <div>{{$t('text.t103')}}</div>
            <div>{{info.amount}}</div>
          </div>
          <div class="b8-t2 between-center">
            <div>{{$t('text.t104')}}</div>
            <div class="end-center">
              <div>{{info1.tokenContractAddress?.substr(0,10)}}...{{info1.tokenContractAddress?.substr(info1.tokenContractAddress?.length-8)}}</div>
              <img src="@/assets/img/copy.png" @click="copy(info1.tokenContractAddress)" alt="" class="b8-imgcopy" />
            </div>
          </div>
          <div class="b8-t2 between-center">
            <div>{{$t('text.t105')}}</div>
            <div>{{info1.tokenId}}</div>
          </div>
          <div class="b8-t2 between-center">
            <div>{{$t('text.t106')}}</div>
            <div>{{info.type==0?'ERC-721':info.type==1?'ERC-1155':''}}</div>
          </div>
          <div class="b8-t2 between-center">
            <div>{{$t('text.t107')}}</div>
            <div>{{chainId=='0x2d20'?'OMP':'BSC'}}</div>
          </div>
          <template v-if="info.createFee && info.createFee != 0">
            <div class="b8-t2 between-center">
              <div>{{$t('text.t108')}}</div>
              <div>{{info.creator?.substr(0,10)}}...{{info.creator?.substr(info.creator?.length-8)}}</div>
            </div>
            <div class="b8-t2 between-center">
              <div>{{$t('text.t109')}}</div>
              <div>{{info.createFee}}</div>
            </div>
          </template>
        </div>
        <div class="box8">
          <div class="b8-t1">{{$t('text.t248')}}</div>
          <div class="b8-t2 b8-t3" v-html="info1.description"></div>
          <!-- <div class="b8-t2 b8-t3">{{info1.description}}</div> -->
        </div>
      </div>
      <div class="box5" v-if="istruenft == 1">
        <div class="b5-t1">{{$t('text.t111')}}</div>
        <div class="b5-t2">
          <el-select v-model="times" :placeholder="$t('text.t112')">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="b5-t3 center-center" ref="chart">
          <!-- <img src="@/assets/img/img55.png" alt="" class="b5-t3-i1" /> -->
        </div>
      </div>
      <div class="box4" v-if="istruenft == 1">
        <div class="b4-t1">{{$t('text.t113')}}</div>
        <div class="b4-b1 between-center">
          <div class="b4-b1-t1">{{$t('text.t114')}}</div>
          <div class="b4-b1-t1">{{$t('text.t115')}}</div>
          <div class="b4-b1-t1">{{$t('text.t116')}}</div>
          <div class="b4-b1-t1">{{$t('text.t117')}}</div>
          <div class="b4-b1-t1">{{$t('text.t118')}}</div>
        </div>
        <div>
          <div class="b4-b2 between-center" v-for="(t, i) in 3" :key="i">
            <div class="b4-b2-t1">
              <img src="@/assets/img/img53.png" alt="" class="b4-b2-i1" />
            </div>
            <div class="b4-b2-t1 center-center">
              <img src="@/assets/img/img44.png" alt="" class="b4-b2-i2" />
              <div>123.45678</div>
            </div>
            <div class="b4-b2-t1">125477</div>
            <div class="b4-b2-t1">12312</div>
            <div class="b4-b2-t1">2022/7/25</div>
          </div>
        </div>
      </div>
    </div>
    <van-popup round v-model="pshow">
      <div class="po-box column-center" v-loading="isloading">
        <div class="pb-t1">{{type==2?$t('text.t119'):$t('text.t120')}}</div>
        <div class="pb-t2">{{type==2?$t('text.t121'):$t('text.t122')}}</div>
        <div class="pb-t3">
          <van-stepper integer disable-input :min="info.number==0?0:1" :max="info.number" v-model="stepNum" />
        </div>
        <div v-if="type==2" class="box3 center-center" @click="downNow">
          <div>{{$t('text.t100')}}</div>
        </div>
        <div v-else-if="type==3" class="box3 center-center" @click="buyNow">
          <img src="@/assets/img/img37.png" alt="" class="box3-i1" />
          <div>{{$t('text.t101')}}</div>
        </div>
        <div class="pb-t4">{{$t('text.t124')}}{{info.number}}{{$t('text.t125')}}{{type==2?$t('text.t100'):$t('text.t123')}}</div>
      </div>
    </van-popup>
  </div>
</template>
<script>
import { mapState } from "vuex";
import BigNumber from "bignumber.js";
export default {
  components: {
  },
  data() {
    return {
      type: '', // 1未上架 2已上架 3购买
      istruenft: 2, //1显示 2隐藏
      times: "",
      options: [
        {
          value: "1",
          label: "七天内",
        },
        {
          value: "2",
          label: "半个月内",
        },
        {
          value: "3",
          label: "一个月内",
        },
        {
          value: "4",
          label: "半年内",
        },
      ],
      id:'',
      isup:'',
      info:{},
      info1:{},
      orderno:'',
      pshow:false,
      stepNum:1,
      isloading:false,
      tmsg:''
    };
  },
  computed: {
    ...mapState(["device","language","chainId","config"]),
  },
  created() {
    this.id = this.$route.query.id
    this.orderno = this.$route.query.orderno
    this.type = this.$route.query.isup ? 1 : ''
    if(this.type == 1){
      this.getdetail()
    } else{
      this.getNewdetail()
    }
  },
  mounted() {
    // this.getEchartData()
  },
  methods: {
    gofans(){
      this.$http.post('/order/fans',{
        orderno:this.info.orderno,
        language:this.language
      }).then(res=>{
        this.$toast(res.msg)
        this.info.is_fans = this.info.is_fans == 0 ? 1 : 0
      })
    },
    goLike(){
      this.$http.post('/collection/likeTokenid',{
        orderno:this.info.orderno,
        language:this.language
      }).then(res=>{
        this.$toast(res.msg)
        this.info.is_like == 2 ? this.info1.heat ++ : this.info1.heat --
        this.info.is_like = this.info.is_like == 2 ? 1 : 2
      })
    },
    async buyNow(){
      if(this.info.number < 1) return
      try {
        this.isloading = true
        await this.$http.post('/order/checkPrice')
        const res = await this.$http.post('/order/buyOrder',{
          orderno:this.orderno,
          language:this.language,
          number:this.stepNum
        })
        const {seller,address,contract,tokenid,number,price,orderno} = res.data
        const result = await this.checkAllowance(price)
        console.log(result);
        this.buyNft(seller,address,contract,tokenid,Number(number),price,orderno)
      } catch (error) {
        console.log(error);
        this.isloading = false
      }
    },
    async downNow(){
      if(this.info.number < 1) return
      try {
        this.isloading = true
        const res = await this.$http.post('/order/soldOrder',{
          orderno:this.orderno,
          language:this.language,
          number:this.stepNum
        })
        const {address,contract,tokenid,number,price,orderno} = res.data
        const result = await this.checkAllowance(price)
        console.log(result);
        const hash = await this.downNft(address,contract,tokenid,Number(number))
        const rest = await this.sendHash(hash,orderno)
        await this.$http.post('/order/pendingOrder')
        this.$toast(rest.msg)
        this.isloading = false
        this.pshow = false
        // this.getNewdetail()
        setTimeout(() => {
          this.$router.go(-1)
        }, 1000);
      } catch (error) {
        console.log(error);
        this.isloading = false
      }
    },
    async checkAllowance(pay){
      const user_address = this.$local.get('shop:account');
			const web3 = new this.Web3(ethereum);
			let f_max_num = new BigNumber(2).pow(256).minus(1);  //最大值
			const max_num = f_max_num.toString(16)
			console.log(max_num);
			const contract_from = this.chainId == '0x2d20' ? this.config.omp_usdt_contract : this.config.bsc_usdt_contract; // 被授权的合约地址
			const abi_from = this.chainId == '0x2d20' ? JSON.parse(this.config.omp_usdt_abi) :  JSON.parse(this.config.bsc_usdt_abi);
			const contract_to = this.chainId == '0x2d20' ? this.config.omp_market_contract : this.config.bsc_market_contract; // 授权的合约地址
			
			const contract_proto = new web3.eth.Contract(abi_from, contract_from,{from:user_address});
			const isAllowance = await contract_proto.methods.allowance(user_address,contract_to).call();
			console.log(isAllowance);
			console.log(new BigNumber(isAllowance).gte(web3.utils.toWei(pay)));
			if(new BigNumber(isAllowance).gte(web3.utils.toWei(pay))){
				return true
			} else{
				const result1 = await contract_proto.methods.approve(contract_to,'0x'+max_num).send()
				console.log(result1);
        return true
			}
		},
    buyNft(seller,address,contract,tokenid,number,price,orderno){
      const web3 = new this.Web3(ethereum);
      const contract_to = this.chainId == '0x2d20' ? this.config.omp_market_contract : this.config.bsc_market_contract;
      const abi_to = this.chainId == '0x2d20' ? JSON.parse(this.config.omp_market_abi) : JSON.parse(this.config.bsc_market_abi);
			const nft_contract = new web3.eth.Contract(abi_to, contract_to,{from:address});
      nft_contract.methods.buyTokenUsingUSDT(seller,contract,tokenid,number,web3.utils.toWei(price)).send({from: address,gasPrice: "3500000000"}).on('transactionHash',async (hash)=>{
				const rest = await this.sendHash(hash,orderno)
        this.tmsg = ''
        this.tmsg = rest.msg
			}).on('receipt',(receipt)=>{
        this.$http.post('/order/pendingOrderBuy',{orderno}).then(()=>{
          this.$toast(this.tmsg)
          this.isloading = false
          this.pshow = false
          this.$http.post('/order/checkPrice')
          setTimeout(() => {
          this.$router.go(-1)
        }, 1000);
        })
			}).on('error', (error, receipt)=>{
				console.log(error,receipt);
        this.isloading = false
			})
    },
    async downNft(address,contract,tokenid,number){
      const web3 = new this.Web3(ethereum);
      const contract_to = this.chainId == '0x2d20' ? this.config.omp_market_contract : this.config.bsc_market_contract;
      const abi_to = this.chainId == '0x2d20' ? JSON.parse(this.config.omp_market_abi) : JSON.parse(this.config.bsc_market_abi);
			const nft_contract = new web3.eth.Contract(abi_to, contract_to,{from:address});
      const {transactionHash} = await nft_contract.methods.cancelAskOrder(contract,tokenid,number).send()
      return transactionHash
    },
    async sendHash(hash,orderno){
			const arg = {
				hash,
				orderno,
				language:this.language
			}
			const rest = await this.$http.post('/order/addHash',arg)
      return rest
		},
    makePshow(){
      this.pshow = true
    },
    getNewdetail(){
      this.$http.get('/order/marketDetail',{
        orderno:this.orderno,
        language: this.language
      }).then(res=>{
        const userId = this.$local.get('shop:uid')
        this.type = res.data.user_id == userId ? 2 : 3
        console.log(this.type);
        this.info = res.data
        this.info1 = res.data.info
      })
    },
    getdetail(){
      this.$http.get('/collections/collectionDetail',{
        nft_id:this.id,
        language: this.language
      }).then(res=>{
        this.info = res.data
        this.info1 = res.data.info
        
      })
    },
    getEchartData(){
      let chartdom = this.$refs.chart
      let myChart = this.$echarts.init(chartdom);
      let option = {
        color: ['#D9E1F7'],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        },
        // legend: {
        //   data: ['price']
        // },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            axisLine:{
              show:false
            },
            axisTick:{
              show:false
            },
            data: ['7/9', '7/10', '7/11', '7/12', '7/13', '7/14', '7/15','7/9', '7/10', '7/11', '7/12', '7/13', '7/14', '7/15']
          }
        ],
        yAxis: [
          {
            type: 'value'
          }
        ],
        series: [
          {
            name: 'price',
            type: 'line',
            stack: 'Total',
            smooth: true,
            lineStyle: {
              width: 0
            },
            showSymbol: false,
            areaStyle: {
              opacity: 0.7,
              color: new this.$echarts.graphic.LinearGradient(0, 0, 1, 0, [
                {
                  offset: 0,
                  color: '#DFEBF9'
                },
                {
                  offset: 1,
                  color: '#C1DDF5'
                }
              ])
            },
            emphasis: {
              focus: 'series'
            },
            data: [50, 70, 90, 112, 137, 150, 170,50, 70, 90, 112, 137, 150, 170]
          }
        ]
      };
      myChart.setOption(option);
      window.addEventListener("resize", function() {
        myChart.resize()
      })
      this.$on('hook:destroyed',()=>{
        window.removeEventListener("resize", function() {
          myChart.resize();
        });
      })
    },
  },
};
</script>

<style lang="less">
.detail-style {
  .po-box{
    width: 301px;
    padding: 26px;
    box-sizing: border-box;
    .pb-t1{
      font-size: 18px;
      font-weight: bold;
      color: #000000;
      margin-bottom: 10px;
    }
    .pb-t2{
      font-size: 12px;
      font-weight: 500;
      color: #000000;
      margin-bottom: 25px;
    }
    .pb-t3{
      margin-bottom: 25px;
    }
    .box3 {
      width: 248px;
      height: 40px;
      background: #323232;
      border-radius: 10px;
      font-size: 18px;
      font-weight: bold;
      color: #ffffff;
      margin-bottom: 15px;
      .box3-i1 {
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }
    }
    .pb-t4{
      text-align: right;
      width: 248px;
      font-size: 12px;
      font-weight: 500;
      color: #0F0D0C;
    }
  }
  .bigbox {
    padding: 15px 0 80px;
    margin: auto;
    width: 300px;
    .bb-t1 {
      font-size: 12px;
      font-weight: bold;
      color: #284da1;
      margin-bottom: 10px;
    }
    .box1 {
      font-size: 18px;
      font-weight: bold;
      color: #323232;
      margin-bottom: 15px;
      .box1-i1 {
        width: 12px;
        height: 10px;
        margin-right: 6px;
      }
      .box1-t1 {
        font-size: 12px;
      }
    }
    .box2 {
      width: 100%;
      height: auto;
      margin-bottom: 20px;
    }
    .box3 {
      height: 45px;
      background: #323232;
      border-radius: 10px;
      font-size: 18px;
      font-weight: bold;
      color: #ffffff;
      margin-bottom: 20px;
      .box3-i1 {
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }
    }
    .box8 {
      background: #f4f4f4;
      border-radius: 10px;
      padding: 25px 22px;
      margin-bottom: 20px;
      .b8-t1 {
        text-align: center;
        margin-bottom: 24px;
        font-size: 15px;
        font-weight: bold;
        color: #323232;
      }
      .b8-imgcopy{
        width: 13px;
        height: 13px;
        margin-left: 2px;
      }
      .b8-t2 {
        font-size: 12px;
        font-weight: bold;
        color: #323232;
        margin-bottom: 25px;
      }
      .b8-t2:last-of-type {
        margin-bottom: 0;
      }
      .b8-t3 {
        line-height: 1.5;
        font-weight: 500;
      }
    }
    .box4 {
      background: #f4f4f4;
      border-radius: 10px;
      padding: 25px 0;
      text-align: center;
      .b4-t1 {
        margin-bottom: 24px;
        font-size: 15px;
        font-weight: bold;
        color: #323232;
      }
      .b4-b1 {
        margin-bottom: 25px;
        .b4-b1-t1 {
          font-size: 12px;
          font-weight: bold;
          color: #323232;
          width: 20%;
        }
      }
      .b4-b2 {
        margin-bottom: 20px;
        .b4-b2-t1 {
          font-size: 9px;
          font-weight: bold;
          color: #323232;
          width: 20%;
          word-break: break-all;
        }
        .b4-b2-i1 {
          width: 15px;
          height: auto;
        }
        .b4-b2-i2 {
          width: 10px;
          height: 10px;
          margin-right: 3px;
        }
      }
      .b4-b2:last-of-type {
        margin-bottom: 0;
      }
    }
    .box5 {
      background: #f4f4f4;
      border-radius: 10px;
      padding: 25px 22px;
      margin-bottom: 20px;
      .b5-t1 {
        text-align: center;
        margin-bottom: 24px;
        font-size: 15px;
        font-weight: bold;
        color: #323232;
      }
      .b5-t2 {
        margin-bottom: 20px;
        .el-select {
          display: block;
        }
      }
      .b5-t3 {
        width: 100%;
        height: 180px;
        .b5-t3-i1 {
          width: 264px;
          height: 199px;
        }
      }
    }
    .box6 {
      margin-bottom: 10px;
      .box6-i1 {
        width: 25px;
        height: 25px;
        border-radius: 50%;
        margin-right: 10px;
      }
      .b6-b1 {
        flex: 1;
        font-size: 12px;
        font-weight: bold;
        color: #0f0d0c;
      }
      .b6-b2 {
        .box6-i2 {
          width: 20px;
          height: 20px;
          margin-right: 18px;
        }
        .box6-i3 {
          width: 15px;
          height: 19px;
        }
      }
    }
    .box7 {
      .b7-b1 {
        width: 300px;
        border-radius: 10px;
        margin-bottom: 16px;
        font-size: 15px;
        font-weight: bold;
        color: #323232;
      }
    }
  }
@media all and (min-width: 1080px) {
  .bigbox {
    padding: 60Px 0 98Px;
    width: 1058Px;
    margin: auto;
    .bb-t1 {
      font-size: 12px;
      font-weight: bold;
      color: #284da1;
      margin-bottom: 10px;
    }
    .box1 {
      font-size: 18px;
      font-weight: bold;
      color: #323232;
      margin-bottom: 15px;
      .box1-i1 {
        width: 12px;
        height: 10px;
        margin-right: 6px;
      }
      .box1-t1 {
        font-size: 12px;
      }
    }
    .box2 {
      width: auto;
      max-width: 462Px;
      height: 462Px;
      margin-bottom: 0;
    }
    .box3-fa-fa{
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      margin-bottom: 60Px;
      .pc-box3-left{
        width: 500Px;
        height: 500Px;
        background-color: #F4F4F4;
        border-radius: 10Px;
      }
    }
    .box3-fa{
      width: 500Px;
      .pc-add-box{
        .pab-t1{
          font-size: 24Px;
          font-weight: bold;
          color: #284DA1;
          margin-bottom: 30Px;
        }
        .pab-t2{
          font-size: 36Px;
          font-weight: bold;
          color: #0F0D0C;
          margin-bottom: 60Px;
        }
        .pab-t3{
          font-size: 24Px;
          font-weight: bold;
          color: #0F0D0C;
          margin-bottom: 37Px;
          .pab-t3-mb{
            margin-bottom: 20Px;
          }
        }
        .pab-i1{
          width: 40Px;
          height: 41Px;
          margin-right: 20Px;
        }
        .pab-i2{
          width: 30Px;
          height: 26Px;
          margin-right: 26Px;
        }
      }
    }
    .box3 {
      height: 45px;
      background: #323232;
      border-radius: 10px;
      font-size: 18px;
      font-weight: bold;
      color: #ffffff;
      margin-bottom: 0;
      .box3-i1 {
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }
    }
    .box8-fa{
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      margin-bottom: 60Px;
    }
    .box8 {
      width: 500Px;
      box-sizing: border-box;
      background: #f4f4f4;
      border-radius: 10px;
      padding: 25px 22px;
      margin-bottom: 0;
      .b8-t1 {
        text-align: center;
        margin-bottom: 24px;
        font-size: 15px;
        font-weight: bold;
        color: #323232;
      }
      .b8-imgcopy{
        width: 13px;
        height: 13px;
        margin-left: 2px;
      }
      .b8-t2 {
        font-size: 12px;
        font-weight: bold;
        color: #323232;
        margin-bottom: 25px;
      }
      .b8-t2:last-of-type {
        margin-bottom: 0;
      }
      .b8-t3 {
        line-height: 1.5;
        font-weight: 500;
      }
    }
    .box4 {
      background: #f4f4f4;
      border-radius: 10px;
      padding: 25px 0;
      text-align: center;
      .b4-t1 {
        margin-bottom: 24px;
        font-size: 15px;
        font-weight: bold;
        color: #323232;
      }
      .b4-b1 {
        margin-bottom: 25px;
        .b4-b1-t1 {
          font-size: 12px;
          font-weight: bold;
          color: #323232;
          width: 20%;
        }
      }
      .b4-b2 {
        margin-bottom: 20px;
        .b4-b2-t1 {
          font-size: 9px;
          font-weight: bold;
          color: #323232;
          width: 20%;
          word-break: break-all;
        }
        .b4-b2-i1 {
          width: 15px;
          height: auto;
        }
        .b4-b2-i2 {
          width: 10px;
          height: 10px;
          margin-right: 3px;
        }
      }
      .b4-b2:last-of-type {
        margin-bottom: 0;
      }
    }
    .box5 {
      background: #f4f4f4;
      border-radius: 20Px;
      padding: 50Px 100Px;
      margin-bottom: 44Px;
      .b5-t1 {
        text-align: center;
        margin-bottom: 19Px;
        font-size: 30Px;
        font-weight: bold;
        color: #323232;
      }
      .b5-t2 {
        margin-bottom: 60Px;
        .el-select {
          display: block;
        }
      }
      .b5-t3 {
        width: 100%;
        height: 350Px;
        .b5-t3-i1 {
          width: 264px;
          height: 199px;
        }
      }
    }
    .box6 {
      margin-bottom: 10px;
      .box6-i1 {
        width: 25px;
        height: 25px;
        border-radius: 50%;
        margin-right: 10px;
      }
      .b6-b1 {
        flex: 1;
        font-size: 12px;
        font-weight: bold;
        color: #0f0d0c;
      }
      .b6-b2 {
        .box6-i2 {
          width: 20px;
          height: 20px;
          margin-right: 18px;
        }
        .box6-i3 {
          width: 15px;
          height: 19px;
        }
      }
    }
    .box7 {
      .b7-b1 {
        width: 300px;
        border-radius: 10px;
        margin-bottom: 16px;
        font-size: 15px;
        font-weight: bold;
        color: #323232;
      }
    }
  }
}
}
</style>